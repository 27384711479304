header {
  background: url(../../images/home/adao-26.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

header .header-hero {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-top: 8rem;
}

header .header-btn {
  width: fit-content;
  padding: 1rem 3rem;
  margin-top: 2rem;
  background-color: #201f1f;
  color: #fff;
  font-weight: bold;
  font-style: italic;
}

header .header-btn:hover {
  background-color: #5c5959;
  color: #fff;
}

header .header-hero h1,
header .header-hero h5 {
  font-weight: bold;
}

header .header-hero .header-hero-text {
  color: #000;
}

.animate-charcter {
  text-transform: capitalize;
  background-image: linear-gradient(
    -225deg,
    #060228 0%,
    #a99caf 29%,
    #fcf413 58%,
    #690829 72%,
    #fff800 88%,
    #f4f3f4 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* Product Overview */
#main-products {
  padding-bottom: 4rem;
}

.product-overview-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
}

.each-card {
  width: 16rem;
  height: 50vh;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
}

.each-card:hover {
  cursor: pointer;
  opacity: 1;
}

.flip-side-text {
  font-size: 12px;
  text-align: center;
  font-weight: bolder;
  padding: 10px;
}

/* Mobile Heading */

.mobile-heading h2 {
  color: #000;
  font-size: 3rem;
  font-weight: bolder;
}

.mobile-heading h2 span {
  color: #d4b35f;
  font-weight: bolder;
  font-size: 3.5rem;
}

@media (min-width: 375px) and (max-width: 576px) {
  header {
    background: url(../../images/home/adao-26.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width: 100%;
    text-align: center;
  }

  header .header-hero {
    height: 100vh;
    padding-top: 2rem;
  }

  header .header-hero .header-hero-text {
    text-align: center;
    width: 100%;
  }

  header .header-hero .header-hero-text h1 {
    font-weight: bolder;
    font-size: 2rem;
  }

  header .header-hero .header-hero-text h5 {
    font-style: italic;
    font-size: 14px;
    padding-bottom: 1.5rem;
  }

  header .header-hero .header-hero-text p {
    text-align: justify;
    font-size: 14px;
  }

  header .mobile-header-btn {
    width: fit-content;
    padding: 1rem 3rem;
    margin: auto;
    margin-top: 2.5rem;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-style: italic;
  }

  header .mobile-header-btn:hover {
    background-color: #5c5959;
    color: #fff;
  }

  #main-products {
    padding-bottom: 3rem;
  }

  .product-overview-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    padding: 2rem;
  }

  .each-card {
    width: 95%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  header {
    background: url(../../images/home/adao-26.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    width: 100%;
    text-align: center;
  }

  header .header-hero {
    height: 110vh;
    padding-top: 4rem;
  }

  header .header-hero .header-hero-text {
    text-align: center;
    width: 100%;
  }

  header .header-hero .header-hero-text h1 {
    font-weight: bold;
    font-size: 2rem;
  }

  header .header-hero .header-hero-text h5 {
    font-size: 14px;
    padding-bottom: 1.5rem;
  }

  header .header-hero .header-hero-text p {
    text-align: justify;
    font-size: 14px;
  }

  header .mobile-header-btn {
    width: fit-content;
    padding: 1rem 3rem;
    margin: auto;
    margin-top: 2.5rem;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-style: italic;
  }

  header .mobile-header-btn:hover {
    background-color: #5c5959;
    color: #fff;
  }

  .product-overview-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
    padding: 2rem;
  }

  .each-card {
    width: 90%;
    height: 50vh;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  header .header-hero {
    padding-top: 2rem;
  }

  header .header-hero .header-hero-text {
    color: #000;
  }

  header .header-hero .header-hero-text h1 {
    font-size: 2.5rem;
  }

  header .header-hero .header-hero-text h5 {
    font-size: 1rem;
    margin-top: 1rem;
  }

  header .header-btn {
    padding: 1rem 2rem;
    margin-top: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .each-card {
    width: 90%;
    height: 50vh;
  }
}
