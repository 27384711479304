#main-overview {
  background-color: #fff;
  padding: 2rem 0 4rem;
}

main h3 {
  margin-bottom: 3rem;
}

.overview-page {
  text-align: justify;
}
