#home-exhibitions {
  padding: 0 0 5rem;
}

#main-exhibitions {
  background-color: #fff;
  padding: 0 0 6rem;
}

main .gallery-container {
  height: 140vh;
}

main .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  height: auto;
}

main .grid-container .exhibit-column {
  display: grid;
}

main .grid-container .exhibit-column .first-column {
  width: 100%;
  align-items: center;
  background-color: #201f1f;
  padding: 5rem 3rem;
  border-radius: 8px;
}

main .grid-container .exhibit-column .first-column img {
  width: 100%;
  align-content: center;
  margin: auto;
}

main .grid-container .exhibit-column .nested-columns {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
}

main .last-slide-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 1rem;
  background-color: #201f1f;
  align-items: center;
  padding: 5rem 3rem;
  border-radius: 8px;
  height: 100vh;
}

main .last-slide-column img {
  width: 100%;
  border-radius: 8px;
}

main .single-nested-columns {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 2rem;
  height: 100vh;
}

main .single-nested-columns img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.4s;
  height: auto;
}

main .grid-container .exhibit-column img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: transform 0.4s;
  object-fit: contain;
}

main .grid-container .exhibit-column img:hover,
main .single-nested-columns img:hover {
  transform: scale(1.1);
  border-radius: 8px;
}

main .grid-container .exhibit-column .card-image,
main .single-nested-columns .card-image,
main .images-container .card-image {
  overflow: hidden;
}

main .images-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  background-color: #201f1f;
  padding: 5rem 3rem;
  border-radius: 8px;
  align-items: center;
}

main .images-container img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: transform 0.4s;
  object-fit: contain;
}

main .images-container img:hover {
  transform: scale(1.1);
  border-radius: 8px;
}

main .gallery-section {
  padding: 1rem 0;
}

/* main .gallery-section .gallery-buttons {
  position: absolute;
  top: 100%;
  left: 45%;
  font-size: 5rem;
}

main .gallery-section .gallery-buttons button {
  padding: 7px;
  margin-right: 1.2rem;
  background-color: #201f1f;
  border-radius: 50%;
  border-style: none;
}

main .gallery-section .gallery-buttons button:hover {
  background-color: #615f5f;
} */

@media (min-width: 375px) and (max-width: 576px) {
  .mobile-carousel .slide-container {
    height: 80vh;
  }

  .mobile-carousel .gallery-image {
    text-align: center;
    width: 100%;
  }

  .mobile-carousel .carousel-caption {
    background-color: transparent;
    backdrop-filter: blur(50px);
    color: #fff;
    font-weight: bolder;
  }

  .mobile-carousel .gallery-image img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  main .gallery-container {
    height: 110vh;
  }
}

/* @media (min-width: 992px) and (max-width: 1400px) {
  main .grid-container {
    height: 150vh;
  }

  main .last-slide-column {
    height: 150vh;
  }
} */
