.grid4form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 2rem;
}

.info {
  padding: 2rem;
  border-right: 1px groove #bfbaba;
}

.info p {
  font-style: italic;
}

.links-column .socials {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.links-column a:hover {
  color: #bfbaba;
}

form {
  margin-left: 3rem;
}

.field {
  margin-bottom: 1rem;
}

.field label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #777;
}

.field input,
.field textarea {
  display: block;
  width: 80%;
  line-height: 1.5;
  font-size: 14px;
  outline: none;
  padding: 5px 12px;
}

.form-error-message {
  font-size: 12px;
  color: #ff0000;
}

.grid4form input::placeholder,
.grid4form textarea::placeholder {
  font-style: italic;
  font-size: 12px;
}

.form-btn {
  padding: 1rem 3rem;
  margin-top: 1rem;
  background-color: #201f1f;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
}

.form-btn:hover {
  background-color: #5c5959;
  color: #fff;
}

@media (min-width: 375px) and (max-width: 768px) {
  .contact {
    padding-top: 5rem;
  }

  .contact h1 {
    margin-bottom: 3rem;
  }

  .grid4form {
    display: block;
    text-align: center;
  }

  .info {
    padding: 0;
    border-right: none;
  }

  form {
    margin: 3rem 2rem 0;
  }

  .field {
    margin-bottom: 2rem;
  }

  .grid4form input,
  .grid4form textarea {
    width: 100%;
    padding: 12px;
  }
}
