footer {
  background-color: #201f1f;
  color: #fff;
  padding: 1rem 0;
}

footer .navbar-container .navbar-brand img {
  border-radius: 50%;
  width: 18%;
}

footer .navbar-container .navbar-nav a {
  color: #fff;
}

footer .navbar-nav a:hover {
  color: #8f8484;
}

footer .footer-body {
  margin-top: 3rem;
}

footer h6 {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 1rem;
}

footer .section-two,
footer .attribution {
  text-align: center;
}

footer .section-three {
  text-align: center;
}

footer .section-two a,
footer .section-three .socials {
  margin-right: 2rem;
  font-size: 1.3rem;
  color: #fff;
}

footer .section-three a {
  font-size: 1.3rem;
  color: #fff;
}

footer .section-two a:hover,
footer .section-three a:hover {
  color: #6a6969;
}

footer .attribution p {
  font-size: 8px;
  letter-spacing: 4px;
  margin-top: 3rem;
  text-transform: uppercase;
  font-weight: bold;
}

footer .attribution p a {
  color: #ffff00;
}

@media (min-width: 375px) and (max-width: 991px) {
  footer .navbar-brand {
    text-align: center;
  }

  footer .navbar-brand img {
    width: 30%;
    border-radius: 50%;
  }

  footer {
    padding: 1.5rem;
  }

  footer .all-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    margin-top: 4rem;
    /* text-align: center; */
  }

  footer .links-column .socials,
  footer .links-column a {
    margin-right: 1rem;
    font-size: 1rem;
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  footer .section-three .socials {
    margin-right: 1rem;
    font-size: 1.2rem;
    color: #fff;
  }
}
