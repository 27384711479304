body {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* cursor: pointer; */
  font-size: 16px;
}

a {
  color: #000;
  font-family: "Josefin Sans", sans-serif;
  text-decoration: none;
}
