#main-products {
  background-color: #f8f8f8;
}

.section-heading {
  padding: 2rem 0 1rem;
  border-bottom: 2px solid #c1bfbf;
  width: 8%;
  font-weight: bolder;
  font-style: italic;
}

section {
  padding: 1rem 0;
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 3rem;
  text-align: center;
  padding: 0 0 3rem;
}

.card-container .card {
  width: 16rem;
  height: 75vh;
  border: 1.3px solid #403e3e;
  margin: auto;
}

.card-container .card .card-header {
  background-color: #000;
}

.card-container .card .card-image {
  width: 100%;
  height: 50vh;
  overflow: hidden;
}

.card-container .card .card-image img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  transition: transform 0.4s;
}

.card-container .card .card-image img:hover {
  transform: scale(1.2);
}

.card-container .card .card-body {
  background-color: #f4f4f4;
  height: 40vh;
}

.card-container .card .card-body .card-icon {
  color: #5c5959;
  margin-right: 5px;
}

.card-container .card .card-body h6 {
  font-size: 14px;

  font-weight: bolder;
}

.card-container .card .card-body .title-paragraph {
  font-size: 12px;
  font-weight: bold;
}

.card-container .card .card-body .card-paragraph {
  font-size: 13px;
  font-weight: bold;
  height: 10vh;
  line-height: 12px;
}

.card-container .card .card-body .line {
  border: 1px solid #d2d0d0;
  width: 50%;
  margin: auto;
  margin-top: 4px;
  margin-bottom: 10px;
}

.card-container .card .card-body .btn {
  display: block;
  width: 100%;
  color: #fff;
  padding: 10px 0;
}

.card-container .card .card-body .btn-first {
  background-color: #201f1f;
  margin-bottom: 10px;
}

.card-container .card .card-body .btn-second {
  background-color: #5c5959;
  color: #fff;
}

.productCards-heading {
  padding: 2rem 0 0;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
}

.productCards-paragraph {
  margin-bottom: 5rem;
}

.productCards-para-container {
  padding: 0 1rem;
  text-align: justify;
}

/* .go-back, */

.return-link {
  padding: 8rem 0 12px;
}

.return-link a {
  color: #5c5959;
  padding: 0.5rem 1rem;
  margin-top: 9rem;
  font-style: italic;
  text-shadow: #201f1f;
  font-weight: 700;
  font-size: 12px;
}

.return-link a:hover {
  color: rgb(246, 13, 13);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 5%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.modal-content {
  background-color: rgb(240, 232, 232);
  padding: 3rem 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  transition: transform 0.7s ease;
  margin: 0 8rem;
}

.product-container {
  align-items: center;
  height: 60vh;
  width: 100%;
  overflow-y: scroll;
}

.product-image {
  width: 100%;
  text-align: center;
}

.product-image img {
  object-fit: contain;
  width: 60%;
}

.product-container .product-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

.product-container .product-details h4 {
  font-weight: bold;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: #000;
  padding: 10px 1.5rem;
  font-weight: bolder;
  border-style: none;
  border-radius: 8px;
}

.close-button:hover {
  background-color: #5c5959;
}

.modal-overlay .open {
  opacity: 1;
  pointer-events: auto;
}

.modal.open {
  transform: scale(1);
}

.modal-overlay.closed {
  opacity: 0;
  pointer-events: none;
}

.modal.closed {
  transform: scale(0.9);
}

/* Mobile */
.carou-mobile button .btn-prev-next {
  color: #201f1f;
  font-size: 2rem;
}

.carou-mobile .card .card-image {
  width: 100%;
  height: 45vh;
  overflow: hidden;
}

.carou-mobile .card {
  margin: 0 3.5rem;
}

.carou-mobile .card .card-image img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  transition: transform 0.4s;
}

.carou-mobile .card .card-image img:hover {
  transform: scale(1.2);
}

.carou-mobile .card .card-body h6 {
  height: 5vh;
}

@media (min-width: 375px) and (max-width: 576px) {
  .return-link {
    padding: 2rem 0 10px;
  }

  .section-heading {
    padding: 2rem 0 1rem;
  }

  .card-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    text-align: center;
  }

  .card-container .card {
    width: 80%;
    height: 100%;
    margin: auto;
  }

  .card-container .card .card-image {
    width: 100%;
  }

  .card-container .card .card-image img {
    width: 100%;
    height: 80vh;
  }

  .card-container .card .card-body .line {
    width: 100%;
  }

  /* Modal */
  .modal-overlay {
    top: 0;
  }

  .modal-content {
    padding: 2rem 1rem;
    margin: 0 1rem;
  }

  .product-container {
    height: 100%;
  }

  .product-image img {
    object-fit: cover;
    width: 80%;
    overflow-y: scroll;
  }

  .product-container .product-details {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }

  .product-container .product-details h4 {
    font-size: 15px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .return-link {
    padding: 2rem 0 10px;
  }

  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /* Modal */
  .modal-overlay {
    top: 0;
  }

  .product-container .product-details {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .return-link {
    padding: 2rem 0 10px;
  }

  .section-heading {
    padding: 1rem 0;
    border-bottom: 2px solid #c1bfbf;
    width: 8%;
    font-weight: bolder;
    font-style: italic;
  }

  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .card-container .card {
    width: 15rem;
    height: 80vh;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-heading {
    padding: 1rem 0;
    border-bottom: 2px solid #c1bfbf;
    width: 8%;
    font-weight: bolder;
    font-style: italic;
  }

  .card-container .card {
    margin: auto;
    width: 13rem;
    height: 75vh;
    border: 1.3px solid #403e3e;
  }
}
