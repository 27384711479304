/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
  background-color: #fff;
  /* background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
    url(../../images/home/adao-26.jpg);
  background-position: center;
  background-repeat: space;
  background-size: cover; */
  /* animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0); */
}

/* The navigation menu links */
.sidenav a {
  text-decoration: none;
  color: #000;
  display: block;
  font-weight: 500;
  transition: 0.6s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #5c5959;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 3.5rem;
  padding-right: 10px;
  margin-left: 50px;
}

.sidenav .closebtn-logo {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.sidenav .sidenav-header {
  padding: 10px;
}

.sidenav .sidenav-links {
  text-align: center;
  align-items: center;
  padding-top: 8rem;
}

.sidenav .sidenav-links a {
  margin-bottom: 3rem;
}

.sidenav a {
  font-size: 25px;
}

.mobile-nav-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 10px;
  margin: 0;
}

.mobile-nav-grid-hamburger {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
}

.mobile-nav-grid-hamburger:hover {
  color: #5c5959;
}

/* Navbar */
.desktop-nav {
  position: fixed;
  z-index: 100;
  background-color: transparent;
  backdrop-filter: blur(50px);
  width: 100%;
  border-bottom: 1px dotted #898585;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

nav .logo-desktop {
  width: 15%;
}

nav .navbar-nav a {
  color: #000;
  font-weight: 600;
}

nav .navbar-nav .link-white {
  color: #000;
}

nav .navbar-nav .nav-btn {
  background-color: #201f1f;
  color: #fff;
  outline: none;
  padding: 10px 2.5rem;
  border-style: none;
  border-radius: 5px;
  font-weight: 600;
}

nav .navbar-nav .nav-btn:hover {
  background-color: #5c5959;
  color: #fff;
}

nav .navbar-nav .link-current {
  font-weight: bold;
  color: #6a6969;
  border-bottom: 3px solid #6a6969;
}

.mobile-nav {
  width: 100%;
  text-align: left;
}

.mobile-nav .logo-mobile {
  width: 60%;
}

.mobile-nav .logo-overlap-mobile {
  width: 30%;
  padding: 10px 0 0 10px;
}

@media (min-width: 768px) {
  .mobile-nav {
    width: 100%;
    text-align: left;
  }

  .sidenav .sidenav-header {
    padding: 30px;
  }

  .mobile-nav .logo-mobile {
    width: 40%;
  }

  .mobile-nav .logo-overlap-mobile {
    width: 30%;
  }
}
