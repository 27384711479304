#main-expert {
  background-color: #f8f8f8;
  padding: 0 0 5rem;
}

.expert-btn {
  padding: 1rem 3rem;
  margin-top: 3rem;
  background-color: #201f1f;
  color: #fff;
  font-weight: bold;
  font-style: italic;
}

.expert-btn:hover {
  background-color: #5c5959;
  color: #fff;
}

.expert-paragraph {
  text-align: justify;
}

@media (min-width: 375px) and (max-width: 768px) {
  .expert-btn-container {
    text-align: center;
  }
}
