.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 9999;
}

.scroll-to-top-button.show {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top-icon {
  font-size: 25px;
}

@media (min-width: 375px) and (max-width: 576px) {
  .scroll-to-top-button {
    position: fixed;
    bottom: 110px;
    right: 20px;
    width: 80px;
    height: 80px;
  }
}
